import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { ToastService } from 'ng-uikit-pro-standard'

/**
 * Confirm password component
 */
@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
    confirmationCode: string;
    email: string;
    errorMessage: string;
    isSubmitting: boolean = false;
    private sub: any;

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private toastrService: ToastService) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];
        });
        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onConfirmRegistration() {
        if ((this.email == null || this.email.length <= 0) || (this.confirmationCode == null || this.confirmationCode.length <= 0)) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.confirmRegistration(this.email, this.confirmationCode, this);
    }

    cognitoCallback(message: string, result: any) {
        this.isSubmitting = false
        if (message != null) { 
            this.errorMessage = message;
            console.log("confirm failed: " + this.errorMessage);
            this.toastrService.error(this.errorMessage)
        } else {   
            this.toastrService.success("User has been confirmed")
            this.router.navigate(['/login']);
        }
    }
}