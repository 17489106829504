export const environment = {
  production: false,
  environment: 'prod',
  
  region: 'us-east-1',

  identityPoolId: 'us-east-1:39f6d4fe-6c71-4945-ab04-9e83764c4589',
  userPoolId: 'us-east-1_9GhilpT6F',
  clientId: '26jun13av0ol7ldvhk2bq9pjji',
  apiGatewayEndpoint: 'https://vrwy1jsun3.execute-api.us-east-1.amazonaws.com/prod',

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  s3_endpoint: 'assessment-app-results-prod',
  s3_static_endpoint: 'assessment-app-static-prod'
};
