import {
  Compiler, Component, Injector, ViewChild, NgModule, ViewContainerRef, AfterViewInit, OnInit, NgModuleRef
} from '@angular/core';
import { PipesModule } from './pipes.module';
import { ActivatedRoute } from "@angular/router";
import { EmailSelectorService } from '../../services/email-selector.service';
import { CognitoService } from "../../services/cognito.service";
import { ToastService } from 'ng-uikit-pro-standard'

var apigClientFactory = require('aws-api-gateway-client').default;

@Component({
  selector: 'email-loader',
  templateUrl: './email-loader.html',
  styleUrls: ['./email-loader.scss']
})

export class EmailLoader implements OnInit {
  template: string
  data: any
  emails: any
  respond: string = null;
  errorMessage: string;
  responses: any = []
  email: any
  isSubmitting: boolean = false;
  isLoading: boolean = false;
  @ViewChild('dynamicTemplate', { read: ViewContainerRef, static: false }) dynamicTemplate;
  private sub: any;

  constructor(
    private _compiler: Compiler,
    private _injector: Injector,
    private _m: NgModuleRef<any>,
    private route: ActivatedRoute,
    private emailSelectorService: EmailSelectorService,
    private cognitoService: CognitoService,
    private toastrService: ToastService) {

  }

  ngOnInit() {
    this.isLoading = true
    this.template = this.route.snapshot.paramMap.get('template');
    this.emailSelectorService.getEmail(this.template)
      .then((resEmail) => {
        this.email = resEmail       
        this.emailSelectorService.getParams()
          .then((resParams) => {
            this.genComponent(this.email, resParams)
          })
        this.loadParams(this.email.template) 
        this.isLoading = false
      })
      .catch((err)=> {
        this.isLoading = false
        this.toastrService.error("Couldn't load email. Please try to login again.\r\nIf issue persists please contact your system administrator")
      })
      
  }  

  onSend() {
    if (this.respond == null || this.respond.length <= 0) {
      this.errorMessage = "Response is required";
      return;
    }

    this.isSubmitting = true
    this.errorMessage = null;
    let tmpResponses = this.responses.slice()
    tmpResponses.push(this.respond)

    let body = {
      emailId: this.template,
      responses: tmpResponses
    }

    var additionalParams = {
      headers: {
        jwt: sessionStorage.getItem("ILIAD.JWT")
      }
    };
    let url = "/response"
    apigClientFactory.newClient(this.cognitoService.getApiClientProps)
      .invokeApi({}, url, 'POST', additionalParams, body)
      .then((res) => {        
        this.isSubmitting = false
        this.responses.push(this.respond)
        this.respond = null 
        this.toastrService.success("Response sent")
      }).catch((err) => {
        this.isSubmitting = false
        this.toastrService.error(err.message)
      });
  }

  private loadParams(template) {
    var additionalParams = {
      headers: {
        jwt: sessionStorage.getItem("ILIAD.JWT")
      }
    };
    let params = {
      emailId: template
    }
    let url = "/response/{emailId}"
    apigClientFactory.newClient(this.cognitoService.getApiClientProps)
      .invokeApi(params, url, 'GET', additionalParams, {})
      .then((res) => {
        this.responses = res.data.content
        this.template = template
      }).catch((err) => {
        console.log("No responses found");
      });
  }

  private genComponent(email, params) {
    params.date = this.formatDate(email)
    const tmpCmp = Component({ moduleId: module.id, template: email.component })(this.getClass(params));
    const tmpModule = NgModule({ declarations: [tmpCmp], imports: [PipesModule] })(class{});

    this._compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {
        const f = factories.componentFactories[0];
        const cmpRef = f.create(this._injector, [], null, this._m);
        cmpRef.instance.name = 'dynamic';
        this.dynamicTemplate.insert(cmpRef.hostView);
      })
  }

  private getClass(ds) {
    let dataClass = class {
      ds = ds
      now = new Date()
    }
    return dataClass
  }

  private formatDate(email) {
    let date = new Date(localStorage.getItem("ILIAD.BEGINTS"))
    let dateStr = ""
    let symbol = email.datePrecision.substring(0, 1)
    if (symbol == "+") {
      let newDate = date.setDate(date.getDate() + Number.parseInt(email.datePrecision.substring(1, email.datePrecision.length)))
      dateStr = new Date(newDate).toLocaleString('en-US')
    } else if (symbol == "-") {
      let newDate = date.setDate(date.getDate() - Number.parseInt(email.datePrecision.substring(1, email.datePrecision.length)))
      dateStr = new Date(newDate).toLocaleString('en-US')
    } else {
      dateStr = date.toLocaleString('en-US')
    }
    return dateStr
  }


}