import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
// import * as STS from "aws-sdk/clients/sts"
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";

// var apigClientFactory = require('aws-api-gateway-client').default;

export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
    callback(): void;
    callbackWithParam(result: any): void;
}

/**
 * Helper service to interact with AWS Cognito 
 */
@Injectable()
export class CognitoService {

    public static _REGION = environment.region;
    public static _IDENTITY_POOL_ID = environment.identityPoolId;
    public static _USER_POOL_ID = environment.userPoolId;
    public static _CLIENT_ID = environment.clientId;
    public static _API_GATEWAY_ENDPOINT = environment.apiGatewayEndpoint;
    public static _POOL_DATA: any = {
        UserPoolId: CognitoService._USER_POOL_ID,
        ClientId: CognitoService._CLIENT_ID
    };

    public cognitoCreds: AWS.CognitoIdentityCredentials;

    constructor() {
        AWS.config.region = CognitoService._REGION;
    }

    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            CognitoService._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoService._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    private setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    public getCognitoCreds() {
        return this.cognitoCreds;
    }

    public getLoginsMap(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoService._REGION.toLowerCase() + '.amazonaws.com/' + CognitoService._USER_POOL_ID;
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        return logins;
    }

    getCognitoIdentityCredentials(idTokenJwt: string): AWS.CognitoIdentityCredentials {
        let params = {
            IdentityPoolId: CognitoService._IDENTITY_POOL_ID,
            Logins: this.getLoginsMap(idTokenJwt)
        };
        let serviceConfigs: awsservice.ServiceConfigurationOptions = {};
        if (environment.cognito_identity_endpoint) {
            serviceConfigs.endpoint = environment.cognito_identity_endpoint;
        }

        return new AWS.CognitoIdentityCredentials(params, serviceConfigs)

    }

    buildCognitoCreds(idTokenJwt: string): Promise<any> {
        
        let creds = this.getCognitoIdentityCredentials(idTokenJwt)

        return new Promise((resolve, reject) => {
            this.refresh(creds)
                .then((creds) => {
                    resolve(creds)
                })
                .catch((error) => {
                    if (this.getCognitoCreds() != null) this.getCognitoCreds().clearCachedId()
                    this.refresh(creds)
                        .then((creds) => {
                            resolve(creds)
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(error)
                        })
                })
        })
        
    }

    private refresh(creds){
        return new Promise((resolve, reject) => {
            creds.refresh((error) => {
                if (error) {                    
                    reject(error)
                } else {
                    console.log('Successfully logged!');
                    AWS.config.credentials = creds;
                    this.setCognitoCreds(creds);
                    resolve(creds)
                }
            });
        })
    }

    public getCreds(logins): Promise<any> {
        let self = this;
        //get the missing creds (found after subsequent calls)
        return new Promise((resolve, reject) => {
            new CognitoIdentity().getCredentialsForIdentity({
                IdentityId: this.getCognitoCreds().identityId,
                Logins: logins
            }).promise()
                .then((res) => {
                    let creds = self.getCognitoCreds()
                    creds.accessKeyId = res.Credentials.AccessKeyId
                    creds.secretAccessKey = res.Credentials.SecretKey
                    creds.sessionToken = res.Credentials.SessionToken
                    resolve(creds)
                })
                .catch((err) => {
                    console.log("CognitoService: get credentials for identity failed: " + err)
                    reject({ message: err.message });
                })
        })
    }

    get getApiClientProps(): {} {
        return {
            accessKey: sessionStorage.getItem("ILIAD.ACCESSKEY"),
            secretKey: sessionStorage.getItem("ILIAD.SACCESSKEY"),
            sessionToken: sessionStorage.getItem("ILIAD.SESSIONTOKEN"),
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json',
            region: CognitoService._REGION,
            invokeUrl: CognitoService._API_GATEWAY_ENDPOINT
        }
    }

}
