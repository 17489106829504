<div class="center-div">

    <div class="card sm-card">

        <div class="card-body">
            <div class="avatar">
                <img src="assets/logo.gif">
            </div>
            <div class="form-header danger-color">
                <h3>Forgot Password - Verify</h3>
            </div>

            <div *ngIf="errorMessage!=null" class="alert alert-danger">
                {{ errorMessage }}
            </div>

            <form class="form" method="POST" action="#" role="form">

                <div class="md-form">
                    <i class="fa fa-code prefix grey-text"></i>
                    <input id="verificationCode" placeholder="Verification Code" required="" type="text" class="form-control" [(ngModel)]="verificationCode"
                        [ngModelOptions]="{standalone: true}">
                </div>

                <div class="md-form">
                    <i class="fa fa-lock prefix grey-text"></i>
                    <input id="newPassword" placeholder="Password" required="" type="password" class="form-control" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="md-form">
                    <button [disabled]="isSubmitting" (click)="onNext()" type="button" class="btn btn-primary btn-lg btn-block waves-light" mdbRippleRadius>
                            <div *ngIf="!isSubmitting">Save</div>
                            <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isSubmitting"></mdb-progress-bar>
                    </button>
                </div>

            </form>

        </div>

        <div class="card-footer">
            <div class="row">
                <div class="col-12">
                    <a [routerLink]="['/forgot']" class="btn btn-flat btn-lg" role="button" mdbRippleRadius>Go Back</a>
                </div>
            </div>

        </div>
    </div>

</div>