import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from "../services/user.service";
import { ToastService } from 'ng-uikit-pro-standard'

/**
 * Utility to help guards against unauthenticated access
 */
@Injectable()
export class AuthGuard implements CanActivate {

  isLoggedIn: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastrService: ToastService) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    this.isLoggedIn = await this.userService.isAuthenticated();

    if (!this.isLoggedIn) {
      let user = sessionStorage.getItem("ILIAD.IDENTITY")
      if (user != null) {
        console.log("user session expired");
        this.toastrService.error("user session expired")
        this.userService.logout()
      }
      this.router.navigate(['login']);
    }

    return this.isLoggedIn;

  }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return this.userService.isAuthenticated()
  //     .then(authenticated => {        
  //       this.isLoggedIn = authenticated 

  //       if (!this.isLoggedIn) {  
  //         let user = sessionStorage.getItem("ILIAD.IDENTITY")
  //         if(user != null) {
  //           console.log("user session expired");
  //           this.toastrService.error("user session expired")
  //           this.userService.logout()
  //         } 
  //         this.router.navigate(['/login']);
  //       } else             
  //           return this.isLoggedIn;
  //     });
  // }
}
