import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CognitoCallback } from "../../services/cognito.service";
import { UserService } from "../../services/user.service";
import { ToastService } from 'ng-uikit-pro-standard'

/**
 * Resend confirmation code component
 */
@Component({
    selector: 'app-resend',
    templateUrl: './resend.component.html',
    styleUrls: ['./resend.component.scss']
})
export class ResendComponent implements CognitoCallback, OnInit {

    email: string;
    errorMessage: string;
    isSubmitting: boolean = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private toastrService: ToastService) {

    }

    ngOnInit() {
        this.errorMessage = null;
    }

    resendCode() {
        if ((this.email == null || this.email.length <= 0)) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.resendCode(this.email, this);
    }

    cognitoCallback(message: string, result: any) {
        this.isSubmitting = false
        if (message != null) {
            this.errorMessage = message;
            console.log("resend failed: " + this.errorMessage);
            this.toastrService.error(this.errorMessage)
        } else {
            console.log("resend success");
            this.router.navigate(['/confirm', this.email]);
        }
    }
}