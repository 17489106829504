import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CognitoService } from "./cognito.service";
import * as AWS from "aws-sdk/global";
import { environment } from "../../environments/environment";

var apigClientFactory = require('aws-api-gateway-client').default;

@Injectable()
export class ResultsService {

  constructor(private cognitoService: CognitoService) { 
    
  }

  getResults(): Promise<any> {
    return new Promise((resolve, reject) => {
      let additionalParams = {
        headers: {
          jwt: sessionStorage.getItem("ILIAD.JWT"),
          bucket: environment.s3_endpoint
        }
      };
      let url = "/results"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi({}, url, 'GET', additionalParams, {})
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          console.log("ResultsService.getResults:" + err);
          reject({ message: err.message })
        });
    })
  }

  getResult(id): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        id: id
      }
      let additionalParams = {
        headers: {
          jwt: sessionStorage.getItem("ILIAD.JWT"),
          bucket: environment.s3_endpoint
        }
      };
      let url = "/results/{id}"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi(params, url, 'GET', additionalParams, {})
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          console.log("ResultsService.getResult:" + err);
          reject({ message: err.message })
        });
    })
  }

  getFiles(prefix): Promise<any> {
    return new Promise((resolve, reject) => {
      let additionalParams = {
        headers: {
          prefix: prefix,
          bucket: environment.s3_static_endpoint
        }
      };
      let url = "/files"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi({}, url, 'GET', additionalParams, {})
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          console.log("ResultsService.getFiles:" + err);
          reject({ message: err.message })
        });
    })
  }

  getFile(prefix, id): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        id: id
      }
      let additionalParams = {
        headers: {
          prefix: prefix,
          bucket: environment.s3_static_endpoint
        }
      };
      let url = "/files/{id}"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi(params, url, 'GET', additionalParams, {})
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          console.log("ResultsService.getFile:" + err);
          reject({ message: err.message })
        });
    })
  }

}
