import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CognitoCallback } from "../../services/cognito.service";
import { UserService } from "../../services/user.service";
import { ToastService } from 'ng-uikit-pro-standard'

/**
 * Forgot password component
 */
@Component({
    selector: 'app-forgot1',
    templateUrl: './forgot1.component.html',
    styleUrls: ['./forgot1.component.scss']
})
export class Forgot1Component implements CognitoCallback, OnInit {
    email: string;
    errorMessage: string;
    isSubmitting: boolean = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private toastrService: ToastService) {

    }

    ngOnInit() {
        this.errorMessage = null;
    }

    onNext() {
        if (this.email == null || this.email.length <= 0) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.forgotPassword(this.email, this);
    }

    cognitoCallback(message: string, result: any) {
        this.isSubmitting = false
        if (message != null) {
            this.errorMessage = message;
            console.log("forgot failed: " + this.errorMessage);
            this.toastrService.error(this.errorMessage)
        } else {
            this.toastrService.success("Message sent. Check your email for verfication code.")
            this.router.navigate(['/forgot', this.email]);
        }
    }
}

@Component({
    selector: 'app-forgot2',
    templateUrl: './forgot2.component.html',
    styleUrls: ['./forgot2.component.scss']
})
export class Forgot2Component implements CognitoCallback, OnInit, OnDestroy {

    verificationCode: string;
    email: string;
    password: string;
    errorMessage: string;
    isSubmitting: boolean = false;
    private sub: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private toastrService: ToastService) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['email'];
        });
        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onNext() {
        if ((this.verificationCode == null || this.verificationCode.length <= 0) || (this.password == null || this.password.length <= 0)) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.confirmNewPassword(this.email, this.verificationCode, this.password, this);
    }

    cognitoCallback(message: string) {
        this.isSubmitting = false
        if (message != null) {
            this.errorMessage = message;
            console.log("forgot failed: " + this.errorMessage);
            this.toastrService.error(this.errorMessage)
        } else {
            this.toastrService.success("Password reset")
            this.router.navigate(['/login']);
        }
    }

}
