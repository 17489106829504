import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatEmails'
})
export class FormatEmailsPipe implements PipeTransform {

  transform(emails: any[]) {
    // const filtered = emails.filter(result => result.Key.split("/")[1])
    const transformed = emails.map(result => {
      let date = new Date()
      let symbol = result.datePrecision.substring(1,2)
      if(symbol == "+"){
        let newDate = date.setDate(date.getDate() + Number.parseInt(result.datePrecision.substring(2,result.datePrecision.length)))
        result.datePrecision = new Date(newDate)
      } else if(symbol == "-"){
        let newDate = date.setDate(date.getDate() - Number.parseInt(result.datePrecision.substring(2,result.datePrecision.length)))
        result.datePrecision = new Date(newDate)
      } else {
        result.date = date
      } 
    })
    return emails
  }
}
