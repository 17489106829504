<main>
  <!-- <div class="card"> -->
  <!-- <ol class="breadcrumb">
      <li class="breadcrumb-item"></li>
      <li class="breadcrumb-item active">
        Your Emails
      </li>
    </ol> -->
  <!-- <div class="card-body"> -->

  <!-- </div>
  </div> -->
  <!-- <mat-sidenav-container>
    <mat-sidenav opened autosize>test</mat-sidenav>
    <mat-sidenav-content>
      <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isLoading"></mdb-progress-bar>
      <table class="table table-hover" *ngIf="!isLoading">
        <thead class="danger-color" style="color: white">
          <tr>
            <th>Date</th>
            <th>From</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          <tr class="hover" *ngFor="let email of emails | sortgrid: '-date'" (click)="onSelect(email.template)">
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.date | formatDate:'MMMM d'}}</th>
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.from}}</th>
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.subject}}</th>
          </tr>
        </tbody>
      </table>
    </mat-sidenav-content>
  </mat-sidenav-container> -->

  <mat-drawer-container autosize>
    <mat-drawer #drawer mode="side" opened class="danger-color">
      <p style="width: 15vh;">&nbsp;</p>
      <!-- <p style="width: 15vh; text-align: center; margin-top: 2em;"><img src="assets/Iliad Email Logo5.png" class="img-fluid" style="height: 5em"></p> -->
      
    </mat-drawer>
    <div style="position: fixed; width: 100%;" class="danger-color">
      <p style="height: 5vh;">&nbsp;</p>
    </div>
    <!-- <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isLoading"></mdb-progress-bar> -->
    <!-- <table class="table table-hover" style="margin-top: 5%;"> -->
    <!-- <thead class="danger-color" style="color: white" >
          <tr>
            <th>Date</th>
            <th>From</th>
            <th>Subject</th>
          </tr>
        </thead> -->
    <!-- <tbody  *ngIf="isLoading">
          <tr>
            <th><mdb-progress-bar class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar></th>
          </tr>
        </tbody>
        <tbody  *ngIf="!isLoading">
          <tr class="hover" *ngFor="let email of emails | sortgrid: '-date'" (click)="onSelect(email.template)">
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.date | formatDate:'MMMM d'}}</th>
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.from}}</th>
            <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.subject}}</th>
          </tr>
        </tbody>
      </table> -->

    <table mdbTable mdbTableScroll scrollY="true" class="table text-left mb-5 table-responsive table-hover"
      bordered="true" *ngIf="!isLoading">

      <thead class="danger-color" style="color: white">
        <tr>
          <th style="width: 20%">Date</th>
          <th style="width: 30%">From</th>
          <th>Subject</th>
        </tr>
      </thead>
      <tbody>
        <tr class="hover" *ngFor="let email of emails | sortgrid: '-date'" (click)="onSelect(email.template)">
          <th style="width: 20%" [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">
            {{email.date | formatDate:'MMMM d'}}</th>
          <th style="width: 30%" [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.from}}</th>
          <th [ngClass]="(email.isNew)?'bold':'notBold'" scope="row">{{email.subject}}</th>
        </tr>
      </tbody>

    </table>
  </mat-drawer-container>
</main>