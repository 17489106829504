<main *ngIf="userService.timesUp == null">
    <div class="jumbotron">
        <h1 class="h1-responsive">Welcome, {{name}}</h1>
        <p class="lead">Schedule Time: {{beginTime}} - {{endTime}}</p>
        <hr class="my-2">
        <div *ngIf="!userService.canStart">It is not currently your scheduled time. You can only access "Your Emails"
            during your scheduled time</div>
        <div *ngIf="userService.isStarted">Your Schedule Time has begun, you may access "Your Emails"</div>
        <div *ngIf="userService.canStart && !userService.isStarted">
            <div>Click the "Begin Assessment" button when you are ready to begin</div>
            <div style="text-align:center">
                <button (click)="onBegin()" type="button" class="btn btn-primary btn-lg" mdbRippleRadius>
                    <div *ngIf="!isSubmitting">Begin Assessment</div>
                    <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isSubmitting">
                    </mdb-progress-bar>
                </button>
            </div>
        </div>
    </div>
</main>

<main *ngIf="userService.timesUp != null">
    <div class="jumbotron">
        <p class="lead">{{userService.timesUp}}</p>
    </div>
</main>