<header *ngIf="authGuard.isLoggedIn">
  <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark danger-color scrolling-navbar"
    [containerInside]="false">
    <logo>
      <a href="#">
        <img src="assets/Iliad Email Logo5.png" style="width: 7vh; height: 6vh">
      </a>
      <!-- <a class="logo navbar-brand" href="#">
        <strong>ILIAD</strong>
      </a> -->
    </logo>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a *ngIf="!userService.isStarted" class="nav-link disable">Your Emails</a>
          <a *ngIf="userService.isStarted" class="nav-link" routerLink="/emails">Your Emails</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/files">Additional Files</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/faq">FAQ</a>
        </li>
        <!-- <li class="nav-item " >
          <a class="nav-link" routerLink="/help">BlueJeans Help</a>
        </li> -->
        <!-- <li class="nav-item " >
          <a class="nav-link" routerLink="/results">Your Results</a>
        </li> -->
      </ul>
      <form class="form-inline timer" *ngIf="userService.isStarted">
        <!--
        Time Left: &nbsp;<countdown-timer class="{{timeStyle}}" (zeroTrigger)="timeUp()" [end]="userService.endTime()">
        </countdown-timer>
        <countdown-timer style="display: none; font-size: 1px;" (zeroTrigger)="alertTimer()"
          [end]="userService.endTimeMinusOne()"></countdown-timer>
-->
Time Left: &nbsp;<countdown class="{{timeStyle}}" [config]="userService.endTime()" (event)="timeUp($event)"></countdown>
      </form>
      <ul class="navbar-nav nav-flex-icons ml-auto">
        <li class="nav-item ">
          <a class="nav-link" (click)="userService.logout()" href="/">
            <i class="fa fa-lock"></i> Logout</a>
        </li>
      </ul>
    </links>
  </mdb-navbar>
</header>
<hr />