import { BrowserModule } from '@angular/platform-browser';
import { NgModule, COMPILER_OPTIONS, CompilerFactory, Compiler, NO_ERRORS_SCHEMA } from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { MDBBootstrapModulesPro, MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';
import { PipesModule } from './components/emails/pipes.module';
import { CountdownModule } from 'ngx-countdown';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserService } from "./services/user.service";
import { CognitoService } from "./services/cognito.service";
import { RegisterComponent } from './components/register/register.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ResendComponent } from './components/resend/resend.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent, LogoutComponent } from './components/login/login.component';
import { Forgot1Component, Forgot2Component } from './components/forgot/forgot.component';
import { EmailsComponent } from './components/emails/emails.component';
import { EmailSelectorService } from './services/email-selector.service';
import { EmailLoader }  from './components/emails/email-loader';
import { HeaderComponent } from './components/header/header.component';
import { ResultsComponent } from './components/results/results.component';
import { ResultsService } from './services/results.service';
import { FormatEmailsPipe } from './components/emails/format-emails.pipe';
import { SortGridPipe } from './components/emails/sort-grid.pipe';
import { FormatResultsPipe } from './components/results/format-results.pipe';
import { FaqComponent } from './components/faq/faq.component';
import { FilesComponent } from './components/files/files.component';
import { HelpComponent } from './components/help/help.component';
import { MaterialAppModule } from './ngmaterial.module';
import { CacheInterceptor } from './interceptors/cache-interceptor';

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    ConfirmComponent,
    ResendComponent,
    LandingComponent,
    LoginComponent,
    LogoutComponent,
    Forgot1Component,
    Forgot2Component,
    EmailsComponent,
    EmailLoader,
    HeaderComponent,
    ResultsComponent,
    FormatEmailsPipe,
    SortGridPipe,
    FormatResultsPipe,
    FaqComponent,
    FilesComponent,
    HelpComponent
  ],
  entryComponents: [ 
    EmailLoader
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CountdownModule,
    PipesModule,
    MaterialAppModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    MDBSpinningPreloader,
    UserService, 
    CognitoService, 
    EmailSelectorService,
    ResultsService,
    {provide: COMPILER_OPTIONS, useValue: {}, multi: true},
     {provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS]},
     {provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory]},
     { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
