import { Injectable, ComponentFactoryResolver, ViewContainerRef, Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CognitoService } from "./cognito.service";
import * as AWS from "aws-sdk/global";

var apigClientFactory = require('aws-api-gateway-client').default;

@Injectable()
export class EmailSelectorService {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cognitoService: CognitoService,
    private http: HttpClient
  ) {

  }

  getAllEmails(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = "/templates"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi({}, url, 'GET', {}, {})
        .then((res) => {
          // let ds = res.data[0]
          resolve(res.data)
        }).catch((err) => {
          console.log("EmailSelectorService:" + err);
          reject({ message: err.message })
        });
    })
  }

  getEmail(id): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        id: id
      }
      let url = "/templates/{id}"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi(params, url, 'GET', {}, {})
        .then((res) => {
          resolve(res.data)
        }).catch((err) => {
          console.log("EmailSelectorService:" + err);
          reject({message: err.message})
        });
    })
  }

  getParams(): Promise<any> {
    return new Promise((resolve, reject) => {
      var additionalParams = {
        headers: {
          jwt: sessionStorage.getItem("ILIAD.JWT")
        }
      };
      let url = "/params"
      apigClientFactory.newClient(this.cognitoService.getApiClientProps)
        .invokeApi({}, url, 'GET', additionalParams, {})
        .then((res) => {
          let ds = res.data[0]
          resolve(ds)
        }).catch((err) => {
          console.log("EmailSelectorService:" + err);
          reject({ message: err.message })
        });
    })
  }

  async updateParams(status) {
    let body = {
      paramId: sessionStorage.getItem("ILIAD.PARAMSID"),
      status: status
    }

    var additionalParams = {
      headers: {
        jwt: sessionStorage.getItem("ILIAD.JWT")
      }
    };
    let url = "/params"
    await apigClientFactory.newClient(this.cognitoService.getApiClientProps)
      .invokeApi({}, url, 'POST', additionalParams, body)
      .then((res) => {        
        // console.log(res)
      }).catch((err) => {
        console.log("EmailSelectorService:" + err)
      });
  }
}
