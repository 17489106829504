<main>
  <div class="card">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"></li>
      <li class="breadcrumb-item active">
        BlueJeans Help
      </li>
    </ol>
    <div class="card-body">
      <div>
        <table class="table table-hover">
          <thead class="danger-color" style="color: white">
            <tr>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="hover" scope="row">
                <a href="https://support.bluejeans.com/">https://support.bluejeans.com/</a>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>