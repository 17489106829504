import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { AnalysisScheme } from 'aws-sdk/clients/cloudsearch';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  isStarted: boolean
  beginTime: any
  endTime: any
  name: string
  timesUp: string
  isSubmitting: boolean = false;

  constructor(
    public router: Router,
    public userService: UserService) {
  }

  ngOnInit() {
    
    this.name = sessionStorage.getItem("ILIAD.NAME")
    this.isStarted = localStorage.getItem("ILIAD.STARTED") !== "false"
    this.beginTime = localStorage.getItem("ILIAD.BEGINTS")
    this.endTime = localStorage.getItem("ILIAD.ENDTS")
    // this.timesUp = localStorage.getItem("ILIAD.TIMESUP")
  }

  onBegin() {
    this.isSubmitting = true
    this.userService.setStarted()
    this.isSubmitting = false
  }

}