import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CognitoCallback } from "../../services/cognito.service";
import { UserService } from "../../services/user.service";
import { User } from "../../models/user.model";
import { ToastService } from 'ng-uikit-pro-standard'

/**
 * Registration component
 */
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements CognitoCallback, OnInit {
    user: User;
    errorMessage: string;
    isSubmitting: boolean = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private toastrService: ToastService) {
    }

    ngOnInit() {
        this.user = new User();
        this.errorMessage = null;
    }

    onRegister() {
        if ((this.user.email == null || this.user.email.length <= 0) || (this.user.password == null || this.user.password.length <= 0)) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.register(this.user, this);
    }

    cognitoCallback(message: string, result: any) {
        this.isSubmitting = false
        if (message != null) {
            this.errorMessage = message;
            console.log("register failed: " + this.errorMessage);
            this.toastrService.error(this.errorMessage)
        } else { 
            console.log("register success");
            this.router.navigate(['/confirm', result.user.username]);
        }
    }
}