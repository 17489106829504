import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatResults'
})
export class FormatResultsPipe implements PipeTransform {

  transform(results: any[]) {
    const filtered = results.filter(result => result.Key.split("/")[1])
    const transformed = filtered.map(result => result.Key.split("/")[1])
    return transformed
  }
}