import { Inject, Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { CognitoCallback, CognitoService, LoggedInCallback } from "./cognito.service";
import { EmailSelectorService } from './email-selector.service';
import { CognitoUserSession, CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoAccessToken, CognitoRefreshToken, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { User } from "../models/user.model";
import * as AWS from "aws-sdk/global";
// import * as STS from "aws-sdk/clients/sts"
import { JwtHelperService } from '@auth0/angular-jwt'

const jwtHelper = new JwtHelperService()

/**
 * User based service functions
 */
@Injectable()
export class UserService {

    constructor(
        private cognitoService: CognitoService,
        private emailSelectorService: EmailSelectorService
    ) { }

    register(user: User, callback: CognitoCallback): void {
        let attributeList = [];
        let email = {
            Name: 'email',
            Value: user.email.toLowerCase()
        };
        attributeList.push(new CognitoUserAttribute(email));

        this.cognitoService.getUserPool().signUp(user.email.toLowerCase(), user.password, attributeList, null, function (err, result) {
            if (err) {
                console.log("UserService: signup failed: " + err)
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {
        this.getUserObj(username.toLowerCase()).confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                console.log("UserService: confirm registration failed: " + err)
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    resendCode(username: string, callback: CognitoCallback): void {
        this.getUserObj(username.toLowerCase()).resendConfirmationCode(function (err, result) {
            if (err) {
                console.log("UserService: resend confirmation code failed: " + err)
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    async isAuthenticated(): Promise<boolean> {
        if (!sessionStorage.getItem("ILIAD.USER")) return false
        const accessToken = sessionStorage.getItem('ILIAD.ACCESSTOKEN')
        const refreshToken = new CognitoRefreshToken({ RefreshToken: sessionStorage.getItem('ILIAD.REFRESHTOKEN') })
        console.log("checking if session valid")
        let valid = !jwtHelper.isTokenExpired(accessToken)
        if (valid) {
            console.log("session valid")
            return true
        } else {
            console.log("access token expired")
            const cognitoUser = this.getAuthenticatedUser()
            if (cognitoUser) {
                try {
                    let tokens = await this.refreshSession(cognitoUser, refreshToken)
                    AWS.config.credentials = await this.cognitoService.buildCognitoCreds(sessionStorage.getItem("ILIAD.JWT"))
                    sessionStorage.setItem("ILIAD.ACCESSKEY", AWS.config.credentials.accessKeyId)
                    sessionStorage.setItem("ILIAD.SACCESSKEY", AWS.config.credentials.secretAccessKey)
                    sessionStorage.setItem("ILIAD.SESSIONTOKEN", AWS.config.credentials.sessionToken)
                    console.log("refresh was : true")
                    return true
                } catch(e) {
                    console.log("refresh was : false")
                    return false
                }
                
            } else {
                return false
            }
        }
    }

    private getAuthenticatedUser() {
        return this.cognitoService.getUserPool().getCurrentUser();
    }

    private async refreshSession(cognitoUser: CognitoUser, refreshToken: CognitoRefreshToken): Promise<any> {
        
        return new Promise((resolve, reject) => {
            return cognitoUser.refreshSession(refreshToken, (err, session) => {
                if (err) {
                    console.error("refreshSession: failed", err)
                    return reject;
                }
                const tokens = this.getTokens(session)
                return resolve(tokens)
            })
        })
    }

    private getTokens(session) {
        let accessToken = session.getAccessToken().getJwtToken()
        let idToken = session.getIdToken().getJwtToken()
        let refreshToken = session.getRefreshToken().getToken()
        sessionStorage.setItem("ILIAD.JWT", idToken)
        sessionStorage.setItem("ILIAD.ACCESSTOKEN", accessToken)
        sessionStorage.setItem("ILIAD.REFRESHTOKEN", refreshToken)
        return {
            accessToken: accessToken,
            idToken: idToken,
            refreshToken: refreshToken
        };
    };

    async authenticate(username: string, password: string, callback: CognitoCallback) {
        // console.log("USERNAME: " + username.toLowerCase())
        let authenticationData = {
            Username: username.toLowerCase(),
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        let self = this;

        this.getUserObj(username.toLowerCase()).authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                console.log("UserService: user needs to set password")
                callback.cognitoCallback('User needs to set password.', null);
            },
            onSuccess: async function  (result) {
                
                try {
                    let creds = await self.cognitoService.buildCognitoCreds(result.getIdToken().getJwtToken())
                    AWS.config.credentials = creds;

                    sessionStorage.setItem("ILIAD.IDENTITY", creds.identityId)
                    sessionStorage.setItem("ILIAD.ACCESSKEY", AWS.config.credentials.accessKeyId)
                    sessionStorage.setItem("ILIAD.SACCESSKEY", AWS.config.credentials.secretAccessKey)
                    sessionStorage.setItem("ILIAD.SESSIONTOKEN", AWS.config.credentials.sessionToken)
                    sessionStorage.setItem("ILIAD.SECRET", AWS.config.credentials.accessKeyId)
                    sessionStorage.setItem("ILIAD.JWT", result.getIdToken().getJwtToken())
                    sessionStorage.setItem("ILIAD.ACCESSTOKEN", result.getAccessToken().getJwtToken())                                
                    sessionStorage.setItem("ILIAD.REFRESHTOKEN", result.getRefreshToken().getToken())
                    sessionStorage.setItem("ILIAD.USER", username.toLowerCase())

                    callback.cognitoCallback(null, result)
                } catch(err) {
                    callback.cognitoCallback(err.message, null);
                }
            },
            onFailure: function (err) {
                console.log("UserService: user authentication failed: " + err)
                callback.cognitoCallback(err.message, null);
            },
        });
    }

    logout() {

        // if (this.cognitoService.getCognitoCreds() != null)
        //     this.cognitoService.getCognitoCreds().clearCachedId()

        if (this.cognitoService.getCurrentUser() != null)
            this.cognitoService.getCurrentUser().signOut();

        let n = sessionStorage.length;
        while (n--) {
            let key = sessionStorage.key(n);
            sessionStorage.removeItem(key);

        }
    }

    forgotPassword(username: string, callback: CognitoCallback) {
        this.getUserObj(username.toLowerCase()).forgotPassword({
            onSuccess: function () { },
            onFailure: function (err) {
                console.log("UserService: forgot password failed: " + err)
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        this.getUserObj(email.toLowerCase()).confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback(null, null);
            },
            onFailure: function (err) {
                console.log("UserService: confirm new password failed: " + err)
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    private getUserObj(username): CognitoUser {
        let userData = {
            Username: username.toLowerCase(),
            Pool: this.cognitoService.getUserPool()
        };
        return new CognitoUser(userData);
    }

    private checkTime() {
        let beingTs = new Date(localStorage.getItem("ILIAD.BEGINTS"))
        let endTs = new Date(localStorage.getItem("ILIAD.ENDTS"))
        let currTs = new Date()
        let canStart = (currTs >= beingTs && currTs < endTs) ? true : false
        return canStart
    }

    public get canStart() {
        return this.checkTime()
    }

    public setStarted() {
        if (sessionStorage.getItem("ILIAD.ENDTS_OVER")) {
            let sessionDurationMins = parseInt(sessionStorage.getItem("ILIAD.ENDTS_OVER"))
            let endTime = new Date(new Date().getTime() + sessionDurationMins * 60000)
            localStorage.setItem("ILIAD.ENDTS", endTime.toLocaleString('en-US'))
        }
        localStorage.setItem("ILIAD.STARTED", "true")
    }

    public get isStarted() {
        if (localStorage.getItem("ILIAD.STARTED") != null) {
            return localStorage.getItem("ILIAD.STARTED") !== "false"
        } else {
            return false;
        }
    }

    public endTime() {
        return {stopTime: new Date(localStorage.getItem("ILIAD.ENDTS")).getTime(), notify: [ 60 ]}
    }

    public get timesUp() {
        return localStorage.getItem("ILIAD.TIMESUP")
    }
}
