import { Component, OnInit, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from "../../guards/auth.guard"
import { UserService } from "../../services/user.service";
import { EmailSelectorService } from '../../services/email-selector.service';
import { TSResolver } from "../../resolvers/ts.resolver"

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  timeStyle: string

  constructor(
    public userService: UserService,
    private emailSelectorService: EmailSelectorService,
    public authGuard: AuthGuard,
    public tsResolver: TSResolver,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.timeStyle = ""
  }

  async timeUp(time) {
    if(time.left == "60000") this.timeStyle = "blink"
    if(time.left != "0") return
    localStorage.setItem("ILIAD.TIMESUP", "Thank you for your participation in the online assessment.  You will be contacted by your university when the results are ready.") 
    localStorage.setItem("ILIAD.STARTED", "false")
    await this.emailSelectorService.updateParams("true")
    if (this.router.url.startsWith('/faq')) this.router.navigate(['/faq'])
    else if (this.router.url.startsWith('/files')) this.router.navigate(['/files'])
    else this.router.navigate(['/']);
  }

  alertTimer(time) {
    this.timeStyle = "blink"
    
  }

}
