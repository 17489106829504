import { Component, OnInit } from '@angular/core';
import { UserService } from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public options = {
    position: ["top", "right"],
    timeOut: 3000
  }

  constructor(
    private userService: UserService) {

  }

}
