import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from "../services/user.service";

/**
 * Utility to help guards against accessing certain resources while authenticated
 */
@Injectable()
export class UnauthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.userService.isAuthenticated()
          .then(authenticated => {   
            if (authenticated) {  
              this.router.navigate(['/landing']);
            }             
            return !authenticated;
          });
      }
}