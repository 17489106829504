import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UnauthGuard } from './guards/unauth.guard';
import { EmailGuard } from './guards/email.guard';

import { TSResolver } from './resolvers/ts.resolver';

import { LoginComponent, LogoutComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ResendComponent } from './components/resend/resend.component';
import { Forgot1Component, Forgot2Component } from './components/forgot/forgot.component';
import { LandingComponent } from './components/landing/landing.component';
import { EmailsComponent } from './components/emails/emails.component';
import { EmailLoader }  from './components/emails/email-loader';
import { ResultsComponent }  from './components/results/results.component'
import { FaqComponent }  from './components/faq/faq.component'
import { FilesComponent }  from './components/files/files.component'
import { HelpComponent }  from './components/help/help.component'

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'register', component: RegisterComponent, canActivate: [UnauthGuard]},
  {path: 'confirm/:username', component: ConfirmComponent, canActivate: [UnauthGuard]},
  {path: 'resend', component: ResendComponent, canActivate: [UnauthGuard]},
  {path: 'forgot', component: Forgot1Component, canActivate: [UnauthGuard]},
  {path: 'forgot/:email', component: Forgot2Component, canActivate: [UnauthGuard]},            
  {path: 'landing', component: LandingComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: 'emails', component: EmailsComponent, canActivate: [AuthGuard, EmailGuard], resolve: { ts: TSResolver }},
  {path: 'details/:template', component: EmailLoader, canActivate: [AuthGuard, EmailGuard], resolve: { ts: TSResolver }},
  {path: 'results', component: ResultsComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: 'result/:id', component: ResultsComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: 'faq', component: FaqComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: 'files', component: FilesComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: 'help', component: HelpComponent, canActivate: [AuthGuard], resolve: { ts: TSResolver }},
  {path: '', redirectTo: '/landing', pathMatch: 'full'},
  {path: '**', redirectTo: '/login', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, UnauthGuard, EmailGuard, TSResolver]
})
export class AppRoutingModule { }
