<main>
  <div class="card">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"></li>
      <li class="breadcrumb-item active">
        Additional Files
      </li>
    </ol>
    <div class="card-body">
      <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isLoading"></mdb-progress-bar>
      <div *ngIf="!isLoading">
        <table class="table table-hover" *ngIf="hasResults">
          <thead class="danger-color" style="color: white">
            <tr>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of results.data | formatResults" (click)="onSelect(result)">
              <th class="hover" scope="row">{{result}}</th>
            </tr>
          </tbody>
        </table>
        <div *ngIf="!hasResults">
              files are not available yet, please check back later
        </div>
      </div>
    </div>
  </div>
</main>