<div class="center-div">

    <div class="card sm-card">

        <div class="card-body">
            <div class="avatar">
                <img src="assets/logo.gif">
            </div>
            <div class="form-header danger-color">
                <h3>Resend Code</h3>
            </div>

            <div *ngIf="errorMessage!=null" class="alert alert-danger">
                {{ errorMessage }}
            </div>

            <form class="form" method="POST" action="#" role="form">

                <div class="md-form">
                    <i class="fa fa-envelope prefix grey-text"></i>
                    <input id="email" placeholder="Email" required="" type="email" class="form-control" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="md-form">
                    <button [disabled]="isSubmitting" (click)="resendCode()" type="button" class="btn btn-primary btn-lg btn-block waves-light" mdbRippleRadius>
                            <div *ngIf="!isSubmitting">Resend Code</div>
                            <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isSubmitting"></mdb-progress-bar>
                    </button>
                </div>

            </form>

        </div>

        <div class="card-footer">
           
        </div>

    </div>

</div>