import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Email } from '../../models/email.model';
import { EmailSelectorService } from '../../services/email-selector.service';
import { ToastService } from 'ng-uikit-pro-standard'

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {
  emails: any;
  isLoading: boolean = false;

  constructor(
    private emailSelectorService: EmailSelectorService,
    private router: Router,
    private toastrService: ToastService
  ) { 
  }

  ngOnInit() {
    this.emails = JSON.parse(sessionStorage.getItem("ILIAD.EMAILS"))
    if(this.emails == null) {
      console.log("getting emails from db")
      this.isLoading = true
      this.emailSelectorService.getAllEmails()
      .then((res)=>{
        this.isLoading = false
        this.emails = this.formatDate(res)
        sessionStorage.setItem("ILIAD.EMAILS", JSON.stringify(this.emails))
        
      })
      .catch((err)=> {
        this.isLoading = false
        this.toastrService.error("Couldn't load emails list. Please try to login again.\r\n If issue persists please contact info@collegiateassessment.com immediately")
      })
    }
  }

  onSelect(id){
    this.emails.find(email => email.template == id).isNew = false
    sessionStorage.setItem("ILIAD.EMAILS", JSON.stringify(this.emails))
    this.router.navigate(['/details', id])
  }

  private formatDate(emails){
    const transformed = emails.map(result => {
      result.isNew = true
      let date = new Date(localStorage.getItem("ILIAD.BEGINTS"))
      let symbol = result.datePrecision.substring(0,1)
      if(symbol == "+"){
        let newDate = date.setDate(date.getDate() + Number.parseInt(result.datePrecision.substring(1,result.datePrecision.length)))
        result.date = new Date(newDate).toLocaleString('en-US')
      } else if(symbol == "-"){
        let newDate = date.setDate(date.getDate() - Number.parseInt(result.datePrecision.substring(1,result.datePrecision.length)))
        result.date = new Date(newDate).toLocaleString('en-US')
      } else {
        result.date = date.toLocaleString('en-US')
      } 
    })
    return emails
  }

}
