import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ResultsService } from '../../services/results.service';
import { ToastService } from 'ng-uikit-pro-standard'
import { writeFileSync } from 'fs';

var FileSaver = require('file-saver');

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  results: any
  isLoading: boolean = false
  hasResults: boolean = false

  constructor(
    private resultsService: ResultsService,
    private toastrService: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true
    this.resultsService.getResults()
    .then((res)=>{
      this.hasResults = res.data.length > 1
      this.results = res
      this.isLoading = false
    })
    .catch((err)=> {
      this.toastrService.error("Couldn't load results list. Please try to login again.\r\n If issue persists please contact your system administrator")
      this.isLoading = false
    })
  }

  onSelect(id){
    this.isLoading = true
    this.resultsService.getResult(id)
    .then((res)=>{
      let byteCharacters = atob(res.data.attachment);
      let byteNumbers = new Array(byteCharacters.length);

      for (var i = 0; i < byteCharacters.length; i++) 
        byteNumbers[i] = byteCharacters.charCodeAt(i);

      let byteArray = new Uint8Array(byteNumbers)
      var blob = new Blob([byteArray], {type: res.contentType});
      FileSaver.saveAs(blob, id);
      this.isLoading = false

    })
    .catch((err)=> {
      this.toastrService.error("Couldn't load results. Please try to login again.\r\n If issue persists please contact your system administrator")
    })
  }
}


