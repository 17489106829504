import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { UserService } from "../services/user.service";

@Injectable()
export class TSResolver implements Resolve<any> {
    isStarted: boolean
    endTime: string

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
        // this.userService.canStart
        this.isStarted = localStorage.getItem("ILIAD.STARTED") !== "false"
        this.endTime = new Date(localStorage.getItem("ILIAD.ENDTS")).toLocaleString('en-US')
        
        return true
    }
}