import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { CognitoCallback, LoggedInCallback } from "../../services/cognito.service";
import { EmailSelectorService } from '../../services/email-selector.service';
import { UserService } from "../../services/user.service";
import { ToastService } from 'ng-uikit-pro-standard'
import { environment } from "../../../environments/environment";

/**
 * Login component
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements CognitoCallback, OnInit {
    email: string;
    password: string;
    errorMessage: string;
    isSubmitting: boolean = false;
    nonprod: boolean = false;
    environment: any = {}

    constructor(
        private emailSelectorService: EmailSelectorService,
        private userService: UserService,
        private router: Router,
        private toastrService: ToastService
        // public ddb: DynamoDBService
    ) {

        if (this.environment.environment == 'stage') {
           this.nonprod = true 
           this.environment = environment
           this.environment.clientId = ""
           this.environment.userPoolId = ""
           this.environment.identityPoolId = ""
        }
    }

    ngOnInit() {
        this.errorMessage = null;

    }

    onLogin() {
        if ((this.email == null || this.email.length <= 0) || (this.password == null || this.password.length <= 0)) {
            this.errorMessage = "All fields are required";
            return;
        }
        this.email = this.email.toLowerCase()
        this.isSubmitting = true
        this.errorMessage = null;
        this.userService.authenticate(this.email, this.password, this);
    }

    onLogout() {
        this.userService.logout()
    }

    cognitoCallback(message: string, result: any) {        
        if (message != null) {
            this.isSubmitting = false
            this.errorMessage = message;
            if (this.errorMessage === 'User does not exist.') {
                this.errorMessage = null
                // this.errorMessage = "the email address entered does not match the email provided for this assessment"
                this.toastrService.error("the email address and/or password entered is not found, please double check the spelling")
            } else if (this.errorMessage === 'User is not confirmed: please reset password and try again') {
                this.errorMessage = null
                this.toastrService.error("email is not confirmed: please reset password and try again")
                this.router.navigate(['/confirm', this.email]);
            } else {
                console.log("login failed: " + this.errorMessage);
                this.toastrService.error("Failed to login: please check that the email you are using matches the email provided for the assessment. If it does and this message persists, please contact info@collegiateassessment.com immediately")
            }
        } else {

            this.emailSelectorService.getParams()
                .then((resParams) => {
                    if (resParams != null) {
                        let sessionStartDate = resParams.sessionStartDate
                        let sessionStartTime = resParams.sessionStartTime
                        let sessionDurationMins = resParams.sessionDurationMins
                        let sessionDurationOverMins = resParams.sessionDurationOverMins
                        let sessionDone  = resParams.sessionDone

                        let startTime = new Date(sessionStartDate + " " + sessionStartTime);
                        let endTime = new Date(startTime.getTime() + sessionDurationMins*60000)                                                
                        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
                            this.isSubmitting = false
                            console.log("date format is invalid in emailParams collection")
                            this.toastrService.error("your start date has not been setup: please contact info@collegiateassessment.com immediately", null);
                        } else {

                            sessionStorage.setItem("ILIAD.NAME", resParams.first)
                            sessionStorage.setItem("ILIAD.PARAMSID", resParams.paramId)

                            if(sessionDurationOverMins)
                                sessionStorage.setItem("ILIAD.ENDTS_OVER", sessionDurationOverMins)

                            if(sessionDone != null && sessionDone == "reset") {
                                console.log("resetting")
                                localStorage.removeItem("ILIAD.STARTED")
                                localStorage.removeItem("ILIAD.TIMESUP")
                                localStorage.removeItem("ILIAD.BEGINTS")
                                localStorage.removeItem("ILIAD.ENDTS")
                                this.emailSelectorService.updateParams("false")
                            } else if(sessionDone != null && sessionDone == "true") {
                                localStorage.setItem("ILIAD.TIMESUP", "Thank you for your participation in the online assessment.  You will be contacted by your university when the results are ready.")
                            }                            
                                                        
                            if(localStorage.getItem("ILIAD.BEGINTS") == null) localStorage.setItem("ILIAD.BEGINTS", startTime.toLocaleString('en-US'))
                            if(localStorage.getItem("ILIAD.ENDTS") == null) localStorage.setItem("ILIAD.ENDTS", endTime.toLocaleString('en-US'))
                            // this.ddb.writeLogEntry("login");                                                    

                            this.isSubmitting = false
                            this.router.navigate(['/landing']);
                        }
                    } else {
                        this.isSubmitting = false
                        console.log("missing record in emailParams collection")
                        this.toastrService.error("emailParams not found for email: please contact info@collegiateassessment.com immediately", null);
                    }

                })

        }
    }
}

@Component({
    template: ''
})

export class LogoutComponent implements OnInit {

    constructor(
        private userService: UserService,
        private router: Router) { }

    ngOnInit() {
        this.userService.logout();
        this.router.navigate(['login']);
    }

}