<div class="center-div">

    <div class="card sm-card">

        <div class="card-body">
            <div class="avatar">
                <img src="assets/logo.gif">
            </div>
            <div class="form-header danger-color">
                <h3>ILIAD Registration</h3>
            </div>

            <div *ngIf="errorMessage!=null" class="alert alert-danger">
                {{ errorMessage }}
            </div>

            <form class="form" method="POST" action="#" role="form">

                <div class="md-form">
                    <i class="fa fa-envelope prefix grey-text"></i>
                    <input id="email" placeholder="Email" required="" type="email" class="form-control" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}">
                </div>

                <div class="md-form">
                    <i class="fa fa-lock prefix grey-text"></i>
                    <input id="password" placeholder="Password" required="" type="password" class="form-control" [(ngModel)]="user.password"
                        [ngModelOptions]="{standalone: true}">
                </div>

                <div class="md-form">
                    <button  [disabled]="isSubmitting" (click)="onRegister()" type="button" class="btn btn-primary btn-lg btn-block waves-light" mdbRippleRadius>
                            <div *ngIf="!isSubmitting">Register</div>
                            <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isSubmitting"></mdb-progress-bar>
                    </button>
                </div>

            </form>

        </div>

        <div class="card-footer">
            <div class="row">
                <div class="col-12">
                    <a [routerLink]="['/login']" class="btn btn-flat btn-lg" role="button" mdbRippleRadius>Already Registered?</a>
                </div>
            </div>

        </div>
    </div>

</div>