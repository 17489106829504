<main>
    <mat-drawer-container autosize>        
        <mat-drawer #drawer mode="side" opened class="danger-color">          
          <p style="width: 15vh;">&nbsp;</p>
          <!-- <p style="width: 15vh; text-align: center; margin-top: 2em;"><img src="assets/Iliad Email Logo5.png" class="img-fluid" style="height: 5em"></p> -->
        </mat-drawer>
        <div style="position: fixed; width: 100%; z-index: 100;" class="danger-color"><p style="height: 5vh;">&nbsp;</p>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"></li>
                <li class="breadcrumb-item active">
                    <a routerLink="/emails">Your Emails</a>
                </li>
                <li class="breadcrumb-item active">
                    View Email
                </li>
            </ol>
        </div>
        <table class="table" style="margin-top: 10%;">
            <tr>
                <th style="margin: 2%"> 
                    <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isLoading"></mdb-progress-bar>
                    <ng-container #dynamicTemplate *ngIf="!isLoading"></ng-container>
                </th>
              </tr>
              <tr *ngIf="!isLoading">
                  <th style="margin: 2%">
                    <div class="card" *ngIf="!isLoading">
    
                        <div class="card-body">
                
                            <div class="form-header danger-color">
                                <h3>Reply</h3>
                            </div>
                
                            <div *ngIf="errorMessage!=null" class="alert alert-danger">
                                {{ errorMessage }}
                            </div>
                
                            <form class="form" method="POST" action="#" role="form">
                                <div class="form-group basic-textarea">
                                    <textarea class="form-control" id="respond" rows="10" [(ngModel)]="respond" [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                                <div class="form-group">
                                    <button [disabled]="isSubmitting" (click)="onSend()" id="submit" type="button" class="btn btn-primary btn-lg waves-light">
                                        <div *ngIf="!isSubmitting">Send</div>
                                        <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" *ngIf="isSubmitting"></mdb-progress-bar>
                                    </button>
                                </div>
                            </form>
                
                        </div>
                
                        <div class="card-footer">
                            <div class="row">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr *ngFor="let response of responses">
                                            <th scope="row">{{response}}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                
                        </div>
                    </div>
                  </th>
              </tr>
          </table>
      </mat-drawer-container>
    

</main>